<template>
  <div></div>
</template>
<script>
import { todoComputed, todoMethods } from "@/state/helpers";

export default {
  computed: {
    ...todoComputed
  },
  created() {
    let nombreModulo = this.modulo

    if( nombreModulo == null ) {
      let moduloTmp = null
      if(sessionStorage.getItem('argusblack.modulo') != null) {
        moduloTmp = sessionStorage.getItem('argusblack.modulo')
      } else if (localStorage.getItem('argusblack.modulo') != null) {
        moduloTmp = localStorage.getItem('argusblack.modulo')
      }

      if (moduloTmp != null) this.actualizarModuloSeleccionado({ modulo: moduloTmp })
      nombreModulo = moduloTmp
    }

    let moduloSeleccionado = this.modulos.find(mdl => mdl.nombre == nombreModulo)

    if ( nombreModulo == null ) {
      this.$router.push({name: 'modulos'})
    } else if ( moduloSeleccionado != undefined ) {
      this.$router.push({name: moduloSeleccionado.nombreRutaInicio })
    }
  },
  mounted() {
    setTimeout(() => {
      var captchas = document.getElementsByClassName("grecaptcha-badge")
      if(captchas.length > 0) {
        let hijo = captchas[0]
        let padre = hijo.parentNode
        padre.remove()
      }
    }, 500)
  },
  methods: {
    ...todoMethods
  }
}
</script>